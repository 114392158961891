import rangeSlider from 'range-slider-input';

const getDate = (date) => {
    if (!date) return new Date();
    return new Date(date.year, date.month - 1, date.day);
};

const initExposition = (sliderElements, daysFromStartToEnd, daysFromStartToCurrent) => {
    const masksNumber = 5;
    const currentMaskNumber = 
        Math.max(
            Math.min(
                Math.round(daysFromStartToCurrent / daysFromStartToEnd * masksNumber),
                masksNumber
            ), 
            1
        );

    const currentRangeMax = currentMaskNumber;

    sliderElements.forEach((element) => {
        const figureElement = element.parentElement.firstElementChild;
        initMasks(figureElement, masksNumber);

        const slider = rangeSlider(element, {
            min: 0,
            max: currentRangeMax,
            step: 0.1,
            thumbsDisabled: [true, false],
            rangeSlideDisabled: true,
            onInput: (values) => {
                setMasksExposure(figureElement, values[1], masksNumber);
            },
        });
    
        slider.value([0, currentRangeMax]);
    });  
};

const setMasksExposure = (element, value, masksNumber) => {
    const valuesArray = [null, 0, 0, 0, 0, 0, 0];
    const mainNumber = Math.floor(value);
    const fractions = Number((value % 1).toFixed(1));
    valuesArray[mainNumber] = 1 - (fractions * 0.2);
    if(fractions) valuesArray[mainNumber + 1] = fractions;
    
    for (let index = 1; index <= masksNumber; index++) {
        element.children[index].style.opacity = valuesArray[index];
    }
};

const initMasks = (element, masksNumber) => {
    const figure = element;

    for (let index = 1; index <= masksNumber; index++) { 
        const maskElement = document.createElement('span');
        maskElement.classList.add('exposition-item__mask');
        maskElement.classList.add(`exposition-item__mask--${index}`);
        figure.append(maskElement);
    }
};

const sliderElements = document.querySelectorAll(".range-slider");
const oneDay = 24 * 60 * 60 * 1000;
const currentDate = getDate();
const startDate = getDate({
    year: 2024,
    month: 4,
    day: 2,
});
const endDate = getDate({
    year: 2024,
    month: 4,
    day: 9,
});


const daysFromStartToEnd = Math.round((endDate - startDate) / oneDay);
const daysFromStartToCurrent = Math.round((currentDate - startDate) / oneDay);
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const expositionParam = urlParams.get('exposition');
const daysFromStart = expositionParam ?? daysFromStartToCurrent;

if(daysFromStart >= 0) {
    initExposition(sliderElements, daysFromStartToEnd, daysFromStart);
} else {
    document.querySelector("#exposition-section").classList.remove("exposition-section--visible");
}