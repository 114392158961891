import $ from 'jquery';
import 'slick-carousel';

$.event.special.touchstart = {
  setup: function( _, ns, handle ) {
      this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
  }
};

$.event.special.touchmove = {
  setup: function( _, ns, handle ) {
      this.addEventListener('touchmove', handle, { passive: !ns.includes('noPreventDefault') });
  }
};

const desktopViewport = 1025;
const tabletViewport = 700;

$('.collaboration-section__slider').slick({
  infinite: false,
  rows: 2,
  arrows: true,
  slidesToShow: 4,
  slidesToScroll: 4,
  prevArrow: ".arrow--previous",
  nextArrow: ".arrow--next",
  responsive: [
    {
      breakpoint: desktopViewport,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: tabletViewport,
      settings: {
        slidesToShow: 1.1,
        slidesToScroll: 1,
        rows: 1,
      },
    },
  ],
});
