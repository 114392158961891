import $ from 'jquery';

$(document).ready(function(){
  $('.navbar-nav a').click(function(){
    $('.navbar-nav a').removeClass('active');
    $(this).addClass('active');
  });

  $('.offcanvas').click(function(){
    $(this).removeClass('show');
    $(this).addClass('offcanvas-end');
    $('.offcanvas-backdrop').remove();
  });
});

